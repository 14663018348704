import I18n from 'i18n-js'

import en from './en.json'
import fr from './fr.json'
import es from './es-ES.json'
import nl from './nl.json'
import de from './de.json'
import it from './it.json'
import th from './th.json'
import cz from './cs.json'
import bg from './bg.json'
import tr from './tr.json'
import ru from './ru.json'
import rs from './sr-CS.json'
import gr from './el.json'
import ja from './ja.json'
import pt from './pt-PT.json'
import pl from './pl.json'
import br from './pt-BR.json'
import cn from './zh-CN.json'
import sa from './ar.json'
import vi from './vi.json'
import ua from './uk.json'
import cs from './cs.json'
import ko from './ko.json'
import no from './no.json'

import { domLocales } from '@domoscio/domoscio-sdk-js'

I18n.fallbacks = true
I18n.translations = {
  en,
  fr,
  es,
  nl,
  de,
  it,
  th,
  cz,
  bg,
  tr,
  ru,
  rs,
  gr,
  ja,
  pt,
  pl,
  br,
  cn,
  sa,
  vi,
  ua,
  cs,
  ko,
  no
}

// Merge src & domLocales translations
I18n.translations = I18n.extend(I18n.translations || {}, domLocales)

I18n.domoscio = [
  'en',
  'fr',
  'es',
  'nl',
  'de',
  'it',
  'th',
  'cz',
  'bg',
  'tr',
  'ru',
  'rs',
  'gr',
  'ja',
  'pt',
  'pl',
  'br',
  'cn',
  'sa',
  'vi',
  'ua',
  'cs',
  'ko',
  'no'
] as string[]

export default I18n
